<template>
  <div>
    <b-card>
      <validation-observer ref="securityFormRef" v-slot="{ passes }">
        <b-form @submit.prevent="passes(searchSecurity)">
          <b-row>
            <b-col cols="12" md="3">
              <b-form-group label-for="secCode" class="w-75">
                <input-with-validation
                  id="secCode"
                  v-model="secCode"
                  rules="required"
                  vid="code"
                  label="Код ценной бумаги"
                  placeholder="Код ценной бумаги"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button variant="primary" type="submit" size="sm">Найти</b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <template v-if="bondData">
      <b-card :title="bondData.name">
        <b-row>
          <b-col cols="6">
            <table>
              <tbody>
                <tr>
                  <td class="pr-1 font-weight-bold">Код</td>
                  <td>
                    <span>{{ bondData.code }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1 font-weight-bold">ISIN</td>
                  <td>
                    <span>{{ bondData.isin }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1 font-weight-bold">Дата начала торгов</td>
                  <td>
                    <span>{{ dateFormatter(bondData.startDate) }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1 font-weight-bold">Дата погашения</td>
                  <td>
                    <span>{{ dateFormatter(bondData.endDate) }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1 font-weight-bold">Лет до погашения</td>
                  <td>
                    <span>{{ decimalFormatter(bondData.yearsMaturity) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="6">
            <table>
              <tbody>
                <tr>
                  <td class="pr-1 font-weight-bold">Эмитент</td>
                  <td>
                    <span>{{ bondData.emitent }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1 font-weight-bold">Группа</td>
                  <td>
                    <span>{{ bondData.groupName }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1 font-weight-bold">Тип</td>
                  <td>
                    <span>{{ bondData.typeName }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col cols="12" md="6">
          <b-card>
            <p>Номинал: {{ decimalFormatter(bondData.faceValue) }}</p>
            <p>
              Цена: {{ percentFormatter(bondData.price) }} от номинала:
              {{ decimalFormatter(bondData.absolutePrice) }}
            </p>
            <p>
              Комиссия брокера ({{ percentFormatter(bondData.portfolioCommission) }}):
              {{
                decimalFormatter((bondData.absolutePrice * bondData.portfolioCommission) / 100) || 0
              }}
            </p>
            <p>НКД: {{ decimalFormatter(bondData.nkd) }}</p>
          </b-card>
          <b-card>
            <p>Купонная доходность: {{ percentFormatter(bondData.couponYeld) }}</p>
            <p>
              Текущая купонная доходность: {{ percentFormatter(bondData.couponYeldCurrent * 100) }}
            </p>
            <p>Доходность к погашению: {{ percentFormatter(bondData.yeldPercent * 100) }}</p>
            <p>
              Доходность к погашению с учетом налогов:
              <span class="font-weight-bold">{{
                percentFormatter(bondData.yeldWTaxPercent * 100)
              }}</span>
            </p>
          </b-card>
        </b-col>
        <b-col cols="12" md="6">
          <b-card title="График платежей">
            <b-table
              class="position-relative"
              :fields="scheduleFields"
              :items="bondData.paymentSchedule"
              responsive
              small
            />
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import InputWithValidation from "@/components/inputs/InputWithValidation.vue";
import { bondYeld } from "@/api/investments";
import { asPercent, asDecimal, asDate } from "@/utils/formatter";

export default {
  name: "BondYeld",
  components: {
    ValidationObserver,
    InputWithValidation,
  },
  data() {
    return {
      secCode: "",
      bondData: null,
      scheduleFields: [
        {
          key: "paymentDate",
          label: "Дата",
          formatter: (val) => new Date(val).toLocaleDateString("ru-RU"),
        },
        { key: "typeName", label: "Тикер" },
        {
          key: "value",
          label: "Размер выплаты",
          formatter: (val) => (!val ? null : asDecimal(val)),
        },
      ],
    };
  },
  computed: {
    comission() {
      return 0;
    },
  },
  methods: {
    async searchSecurity() {
      this.bondData = null;
      const success = await this.$refs.securityFormRef.validate();
      if (!success) {
        return;
      }
      try {
        const response = await bondYeld({
          sec_code: this.secCode,
        });
        this.bondData = response;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ошибка",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Ошибка загрузки данных",
          },
        });
      }
    },
    percentFormatter(value) {
      return asPercent(value);
    },
    decimalFormatter(value) {
      return asDecimal(value);
    },
    dateFormatter(value) {
      return asDate(value);
    },
  },
};
</script>

<style></style>
